import React, { Fragment, useEffect, useState } from 'react'
import '../styles/css/home.css'
import Sidebar from '../components/Sidebar'
import Header from '../components/Header'
import Playground from '../components/Playground'
// import session from '../utils/session'
// import api from '../api/axios'
import { useNavigate } from 'react-router-dom'

const Home = () => {
  const navigate = useNavigate()
  const [islogged, setIsLogged] = useState(true)
  useEffect(() => {
    const checkauth = async () => {
      // if (!session.get().authToken || session.get().authToken === undefined) {
      //   // No token found, redirect to login page
      //   console.log('not logged in (token not found)')
      //   setIsLogged(false)
      //   navigate('/auth')
      // } else {
      //   const token = session.get().authToken
      //   const res = await api('POST', 'auth/verify', { token })
      //   if (res.data.type !== 'success') {
      //     console.log('not logged in (invalid token)')
      //     navigate('/auth')
      //     setIsLogged(false)
      //   }
      //   setIsLogged(true)
      // }
      setIsLogged(true)
    }
    checkauth()
  }, [navigate])
  return (
    <Fragment>
      {islogged ? (
        <div className="app">
          <div className="homecontainer">
            <div className="homewrapper">
              <div className="homelayout">
                <Sidebar />
                <div className="hmelayright">
                  <Header />
                  <Playground />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </Fragment>
  )
}

export default Home
