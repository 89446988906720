import React, { Fragment, useState } from 'react'
import '../styles/css/sidebar.css'
import { ReactComponent as Bars } from '../assets/svg/bars.svg'
import { ReactComponent as Home } from '../assets/svg/home.svg'
import { ReactComponent as Pen } from '../assets/svg/pen.svg'
import { ReactComponent as Blogs } from '../assets/svg/blogs.svg'
import { ReactComponent as Contact } from '../assets/svg/message.svg'
import { ReactComponent as Comments } from '../assets/svg/comments.svg'
import { ReactComponent as Files } from '../assets/svg/files.svg'
import { ReactComponent as User } from '../assets/svg/user.svg'
import { ReactComponent as Logs } from '../assets/svg/history.svg'
import { ReactComponent as Ban } from '../assets/svg/ban.svg'
import { ReactComponent as Gear } from '../assets/svg/gear.svg'
import { useAppDispatch } from '../state/hooks'
import { setTab } from '../state/actions/tabAction'

const Sidebar = () => {
  const [sidewidth, setSidewidth] = useState(true)
  //current tab
  const dispatch = useAppDispatch()

  return (
    <Fragment>
      <div className={sidewidth ? 'sidebar' : 'sidebar sidemin'}>
        <div className="sidebTop">
          <Bars className="sbBarsIc" onClick={() => setSidewidth(!sidewidth)} />
        </div>
        <div className="sidebarnavigation">
          <div
            className="sidebarnavlink"
            onClick={() => {
              dispatch(setTab('dashboard'))
            }}
          >
            <div className="sbNavIcContainer">
              <Home className="sbNavIc" />
            </div>
            <span>Home</span>
          </div>
          <div
            className="sidebarnavlink"
            onClick={() => {
              dispatch(setTab('blog'))
            }}
          >
            <div className="sbNavIcContainer">
              <Pen className="sbNavIc" />
            </div>
            <span>new blog</span>
          </div>
          <div
            className="sidebarnavlink"
            onClick={() => {
              dispatch(setTab('blogs'))
            }}
          >
            <div className="sbNavIcContainer">
              <Blogs className="sbNavIc" />
            </div>
            <span>Blogs</span>
          </div>
          <div
            className="sidebarnavlink"
            onClick={() => {
              dispatch(setTab('contact'))
            }}
          >
            <div className="sbNavIcContainer">
              <Contact className="sbNavIc" />
            </div>
            <span>Contacts</span>
          </div>
          <div
            className="sidebarnavlink"
            onClick={() => {
              dispatch(setTab('comments'))
            }}
          >
            <div className="sbNavIcContainer">
              <Comments className="sbNavIc" />
            </div>
            <span>Comments</span>
          </div>
          <div
            className="sidebarnavlink"
            onClick={() => {
              dispatch(setTab('files'))
            }}
          >
            <div className="sbNavIcContainer">
              <Files className="sbNavIc" />
            </div>
            <span>Files</span>
          </div>
          <div
            className="sidebarnavlink"
            onClick={() => {
              dispatch(setTab('partners'))
            }}
          >
            <div className="sbNavIcContainer">
              <User className="sbNavIc" />
            </div>
            <span>Partners</span>
          </div>
          <div
            className="sidebarnavlink"
            onClick={() => {
              dispatch(setTab('logs'))
            }}
          >
            <div className="sbNavIcContainer">
              <Logs className="sbNavIc" />
            </div>
            <span>Logs</span>
          </div>
          <div
            className="sidebarnavlink"
            onClick={() => {
              dispatch(setTab('ban'))
            }}
          >
            <div className="sbNavIcContainer">
              <Ban className="sbNavIc" />
            </div>
            <span>BLocked Users</span>
          </div>
          <div
            className="sidebarnavlink"
            onClick={() => {
              dispatch(setTab('settings'))
            }}
          >
            <div className="sbNavIcContainer">
              <Gear className="sbNavIc" />
            </div>
            <span>Settings</span>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Sidebar
