import React, { Fragment, useEffect, useState } from 'react'
import '../styles/css/files.css'
import { ReactComponent as Upload } from '../assets/svg/icon-download.svg'
import api from '../api/axios'
import { backend } from '../utils/backend'
import { toast } from 'react-toastify'

const Files = () => {
  const [files, setFiles] = useState([])
  useEffect(() => {
    const fetchfiles = async () => {
      const res = await api('GET', 'files', {})
      if (res.status === 200) {
        setFiles(res.data.files)
      } else {
        toast(res.data.message, { type: 'error' })
      }
    }
    fetchfiles()
  }, [])
  console.log(files)
  return (
    <Fragment>
      <div className="admfiles">
        <div className="files-wrapper">
          <div className="files-top">
            <h1 className="files-title">Files</h1>
          </div>
          <div className="files-body">
            <div className="files-b-wrapper">
              <form className="files-upload-form">
                <div className="files-form-group">
                  <div className="input-field-row">
                    <div className="input-file-row">
                      <label htmlFor="project_brief" className="project_brief">
                        <div className="file-content">
                          <div className="icon">
                            <Upload className="image-upload-icon" />
                          </div>
                          <div className="content-label">
                            Drag or browse your files here
                          </div>
                        </div>
                        <div className="row-wrap">
                          <div id="filePush" className="button">
                            upload
                          </div>
                        </div>
                      </label>
                      <input
                        className="file-field"
                        type="file"
                        name="images"
                        multiple
                        //   onChange={(e) =>
                        //     setImages(Array.from(e.target.files || []))
                        //   }
                      />
                    </div>
                    <span id="id-err fileErr"></span>
                  </div>
                </div>
              </form>
              <div className="files-uploaded-preview">
                <div className="flsprev-items">
                  {Array.isArray(files) &&
                    files.map((i) => {
                      return (
                        <div className="flsprev-item" key={i}>
                          <img
                            className="flprevitimage"
                            src={`${backend()}uploads/${i}`}
                            alt=""
                          />
                          <span className="flprevitname">{i}</span>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Files
