import axios from 'axios'
import { backend } from '../utils/backend'

const api = async (
  method: string = 'GET',
  slug: string,
  data: object = {},
  headers: object = {},
  timeout: number = 120000
): Promise<any> => {
  try {
    const respip: { ip: string } = await axios.get(
      'https://api.ipify.org/?format=json'
    )
    const config = {
      method: method,
      maxBodyLength: Infinity,
      url: backend() + 'api/v1/' + slug,
      headers: {
        ...headers,
        ip: respip.ip,
      },
      data: data,
      withCredentials: true,
      timeout: timeout,
    }
    const res = await axios(config)
    return res
  } catch (error: any) {
    console.log(error)
    if (axios.isCancel(error)) {
      return {
        ...error,
        data: { message: 'Request timed out' },
        status: 400,
      }
    } else if (error.code === 'ERR_NETWORK') {
      return {
        ...error.response,
        data: { message: 'Connection timed out! Network Error' },
        status: error.response?.status || 400,
      }
    } else if (error.response) {
      return {
        ...error.response,
        status: error.response?.status || 400,
      }
    }
    return { ...error.response, status: error.response?.status || 400 }
  }
}

export default api
