import React, { Fragment, useState } from 'react'
import '../styles/css/auth.css'
import Logo from '../assets/images/nullchemy_new_logo_3d.png'
import { Link } from 'react-router-dom'
import api from '../api/axios'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppDispatch } from '../state/hooks'
import { setIsLogged } from '../state/actions/auth'

const Auth = () => {
  const [action, setAction] = useState('login')
  const [status, setStatus] = useState(
    action === 'login'
      ? 'login'
      : action === 'resetpass'
      ? 'request'
      : 'register'
  )
  const [loading, setLoading] = useState(false)
  const [logdata, setLogData] = useState({
    email: '',
    password: '',
  })
  const [resdata, setResData] = useState({
    email: '',
  })
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleLogin = async (e: any) => {
    e.preventDefault()
    try {
      setLoading(true)
      const res = await api('POST', 'admin/signin', logdata)
      setLoading(false)
      console.log(res)
      if (res.status === 200) {
        dispatch(setIsLogged(true))
        setLogData({
          email: '',
          password: '',
        })
        navigate('/')
      } else {
        toast(res.data.message, { type: 'error' })
      }
    } catch (err: any) {
      console.log(err)
      toast(err.response.data.message, { type: 'error' })
    }
    setTimeout(() => {
      setStatus('login')
    }, 2000)
  }
  const handleReset = async (e: any) => {
    e.preventDefault()

    setLoading(true)
    const res: any = await api('POST', 'admin/reset', resdata)
    setLoading(false)
    if (res.status === 200) {
      // clear the form
      setResData({
        email: '',
      })
    } else {
      toast(res.data.message, { type: 'error' })
    }
    setTimeout(() => {
      setStatus('request')
    }, 2000)
  }
  return (
    <Fragment>
      <div className="auth">
        <div className="authContainer">
          <div className="authWrapper">
            {action === 'login' ? (
              <div className="authForm login">
                <div className="authformheader">
                  <Link to="/" className="headerLogoLink">
                    <img src={Logo} alt="" />
                  </Link>
                  <h2>Login</h2>
                </div>
                <form
                  className="authentificationform"
                  method="POST"
                  onSubmit={(e) => handleLogin(e)}
                >
                  <div className="authformgroup">
                    <input
                      type="text"
                      className="authinput"
                      name="email"
                      value={logdata.email}
                      onChange={(e) =>
                        setLogData({
                          ...logdata,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                    <span className="floating-label">
                      Email <span>*</span>
                    </span>
                  </div>
                  <div className="authformgroup">
                    <input
                      type="password"
                      className="authinput"
                      name="password"
                      value={logdata.password}
                      onChange={(e) =>
                        setLogData({
                          ...logdata,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                    <span className="floating-label">
                      Password <span>*</span>
                    </span>
                  </div>
                  <p
                    className="passresetlink"
                    onClick={() => setAction('resetpass')}
                  >
                    Forgot Password?
                  </p>
                  <br />
                  <button type="submit" className="authsubBtn">
                    {loading ? <div className="dot-flashing"></div> : status}
                  </button>
                </form>
              </div>
            ) : action === 'resetpass' ? (
              <div className="authForm login">
                <div className="authformheader">
                  <Link to="/" className="headerLogoLink">
                    <img src={Logo} alt="" />
                  </Link>
                  <h2>Reset password</h2>
                </div>
                <form
                  className="authentificationform"
                  method="POST"
                  onSubmit={(e) => handleReset(e)}
                >
                  <div className="authformgroup">
                    <input
                      type="text"
                      className="authinput"
                      name="email"
                      value={logdata.email}
                      onChange={(e) =>
                        setLogData({
                          ...logdata,
                          [e.target.name]: e.target.value,
                        })
                      }
                    />
                    <span className="floating-label">
                      Email <span>*</span>
                    </span>
                  </div>
                  <p
                    className="passresetlink"
                    onClick={() => setAction('login')}
                  >
                    login
                  </p>
                  <button type="submit" className="authsubBtn">
                    {loading ? <div className="dot-flashing"></div> : status}
                  </button>
                </form>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Auth
