import React, { Fragment } from 'react'

const Comments = () => {
  return (
    <Fragment>
      <h1>Comments</h1>
    </Fragment>
  )
}

export default Comments
