import React, { Fragment, useEffect, useState } from 'react'
import '../styles/css/contacts.css'
import { ReactComponent as Reply } from '../assets/svg/reply.svg'
import { ReactComponent as Download } from '../assets/svg/download.svg'
import { ReactComponent as Trash } from '../assets/svg/trash.svg'
import { ReactComponent as Inbox } from '../assets/svg/inbox.svg'
import api from '../api/axios'

const Contacts = () => {
  const [contacts, setContacts] = useState<any>([])
  useEffect(() => {
    const fetchContacts = async () => {
      const res = await api('GET', 'contact', {})
      console.log(res)
      if (res.status === 200) {
        setContacts(res.data.contacts)
      }
    }
    fetchContacts()
  }, [])
  return (
    <Fragment>
      <div className="contacts">
        {contacts.length !== 0 ? (
          <div className="cWrapper">
            <div className="section-title">
              <h1>Contacts</h1>
              <div className="sec-events">
                <button className="dwld-table">download</button>
                <div className="rows-per-page"></div>
              </div>
            </div>
            <div className="table-wrap">
              <table>
                <thead>
                  <tr style={{ backgroundColor: 'yellow' }}>
                    <td>ID</td>
                    <td>Name</td>
                    <td>Email</td>
                    <td>Subject</td>
                    <td>Message</td>
                    <td>Date & Time</td>
                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(contacts) &&
                    contacts.map((i: any) => {
                      return (
                        <tr key={i.ContactID}>
                          <td>{i.ID}</td>
                          <td style={{ whiteSpace: 'nowrap' }}>{i.Name}</td>
                          <td>{i.Email}</td>
                          <td>{i.Subject}</td>
                          <td>
                            <span>{i.Message}</span>
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {i.TimeStamp}
                          </td>
                          <td>
                            <div className="actions-wrapp">
                              <Reply className="report-action" />
                              <Download className="report-action" />
                              <Trash className="report-action" />
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="noreportsmodal">
            <div className="norepmainwrapper">
              <div className="noreportswrap">
                <Inbox className="inboxsvgicon" />
                <h2>no Contacts</h2>
                <p>your inbox is clean</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default Contacts
