import React, { Fragment, useEffect, useState } from 'react'
import '../styles/css/contacts.css'
import kibet from '../assets/images/kibet.jpg'
import { ReactComponent as Download } from '../assets/svg/download.svg'
import { ReactComponent as Trash } from '../assets/svg/trash.svg'
import { ReactComponent as Inbox } from '../assets/svg/inbox.svg'
import api from '../api/axios'
import { toast } from 'react-toastify'

const Partners = () => {
  const [contacts, setContacts] = useState<any>([])
  useEffect(() => {
    const fetchContacts = async () => {
      const res = await api('GET', 'partners', {})
      console.log(res)
      if (res.status === 200) {
        setContacts(res.data.contacts)
      } else {
        toast(res.data.message, { type: 'error' })
      }
    }
    fetchContacts()
  }, [])
  return (
    <Fragment>
      <div className="contacts">
        {contacts.length !== 0 ? (
          <div className="cWrapper">
            <div className="padmLeadership">
              <h1 className="padmLTitle">Our Leadership</h1>
              <div className="padmlewrapper">
                <div className="padlcard">
                  <div className="padlcimage">
                    <img src={kibet} alt="" />
                  </div>
                  <h1 className="padlcName">Dennis Kibet</h1>
                  <p className="padlcRole">Chief Executive Officer</p>
                  <p className="padlcExpertise">Software Engineer</p>
                  <p className="padlcLevel">Scale: 1</p>
                </div>
              </div>
            </div>
            <div className="section-title">
              <h1>Partners</h1>
              <div className="sec-events">
                <button className="dwld-table">download</button>
                <div className="rows-per-page"></div>
              </div>
            </div>
            <div className="table-wrap">
              <table>
                <thead>
                  <tr style={{ backgroundColor: 'yellow' }}>
                    <td>ID</td>
                    <td>Name</td>
                    <td>Email</td>
                    <td>Role</td>
                    <td>Level</td>
                    <td>Date Joined</td>
                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(contacts) &&
                    contacts.map((i: any) => {
                      return (
                        <tr key={i.ID}>
                          <td>{i.ID}</td>
                          <td style={{ whiteSpace: 'nowrap' }}>{i.Name}</td>
                          <td>{i.Email}</td>
                          <td>{i.Role}</td>
                          <td>
                            <span>{i.Level}</span>
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {i.DateJoined}
                          </td>
                          <td>
                            <div className="actions-wrapp">
                              <Download className="report-action" />
                              <Trash className="report-action" />
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="noreportsmodal">
            <div className="norepmainwrapper">
              <div className="noreportswrap">
                <Inbox className="inboxsvgicon" />
                <h2>no Partners</h2>
                <p>add someone</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default Partners
