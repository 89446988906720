type Action = {
  type: string
  payload?: string
}
const tabReducer = (state: string = 'dashboard', action: Action) => {
  switch (action.type) {
    case 'SETTAB':
      return action.payload
    default:
      return state
  }
}

export default tabReducer
