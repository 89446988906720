import React, { Fragment } from 'react'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import Home from './routes/Home'
import NotFound from './routes/NotFound'
import Auth from './routes/Auth'
import Editor from './routes/Blog'
import Test from './routes/Test'
import ProtectedRoutes from './utils/PrivateRoute'

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/auth" element={<Auth />} />
      <Route path="/test" element={<Test />} />
      <Route path="/editor" element={<Editor />} />
      <Route element={<ProtectedRoutes />}>
        <Route path="/" element={<Home />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </>
  )
)

const Routes = () => {
  return (
    <Fragment>
      <RouterProvider router={router} />
    </Fragment>
  )
}

export default Routes
