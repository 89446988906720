import { combineReducers } from 'redux'
import tabReducer from './tabReducer'
import authReducer from './auth'

const allReducers = combineReducers({
  tab: tabReducer,
  islogged: authReducer,
})

export type allReducerss = ReturnType<typeof allReducers>

export default allReducers
