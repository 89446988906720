import React, { Fragment } from 'react'
import '../styles/css/profile.css'
import ProfilePic from '../assets/images/kibet.jpg'

const Profile = () => {
  return (
    <Fragment>
      <div className="admprofile">
        <div className="profcontainer">
          <div className="profwrapper">
            <h1 className="pageTitle">Profile</h1>
            <div className="proftopcard">
              <div className="pcardoneTop">
                <div className="cardprofImage">
                  <span className="profilePosRelative">
                    <img className="profileimage" src={ProfilePic} alt="" />
                    <span className="profileOnlineDot"></span>{' '}
                  </span>
                </div>
                <div className="cardprofTexts">
                  <h1 className="profName">Dennis Kibet</h1>
                  <p className="profdetails">
                    <strong>Phone:</strong> +254798116710
                  </p>
                  <p className="profdetails">
                    <strong>Email:</strong> dennisrkibet@gmail.com
                  </p>
                </div>
              </div>
              <div className="pcardoneBottom">
                <div className="proftabs">
                  <p className="ptablnk">About</p>
                  <p className="ptablnk">Edit Profile</p>
                  <p className="ptablnk">Timeline</p>
                  <p className="ptablnk">Gallery</p>
                  <p className="ptablnk">Activity</p>
                  <p className="ptablnk">Account Settings</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Profile
