import React, { Fragment, useEffect, useState } from 'react'
import '../styles/css/blog.css'
import { ReactComponent as Upload } from '../assets/svg/icon-download.svg'
import { ReactComponent as Xmark } from '../assets/svg/x-mark.svg'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'
import ImageUpload from '../utils/ImageUpload'
import api from '../api/axios'

const Blog: React.FC = () => {
  const [title, setTitle] = useState<string>('')
  const [images, setImages] = useState<File[]>([])
  const [summary, setSummary] = useState<string>('')
  const [toc, setToc] = useState<string>('')
  const [toclist, setTocList] = useState<Array<{ title: string; id: string }>>(
    []
  )
  const [blogcont, setBlogCont] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [uploading, setUploading] = useState(false)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    const res: any = await api('POST', 'blog/save', {
      title: title,
      summary: summary,
      images: images,
      toc: toclist,
      blogcont: blogcont,
    })
    setIsLoading(false)
    if (res.status === 200) {
      toast('Blog Posted!', { type: 'success' })
      setTitle('')
      setImages([])
      setSummary('')
      setToc('')
      setTocList([])
      setBlogCont('')
    } else {
      console.log(res)
      toast('Could not post the blog', { type: 'error' })
    }
  }

  useEffect(() => {
    const uploadfile = async () => {
      setUploading(true)
      const paths: any[] = []
      if (images.length > 0) {
        for (let i = 0; i < images.length; i++) {
          if (typeof images[i] === 'string') {
            paths.push(images[i])
          } else {
            paths.push(await ImageUpload(images[i]))
          }
        }
      }
      setImages(paths)
      setUploading(false)
    }
    uploadfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images.length])
  return (
    <Fragment>
      <div className="blog">
        <div className="blogContainer">
          <div className="blogWrapper">
            <h1 className="blogTitle">New Blog</h1>
            <div className="blogCont">
              <form
                className="blogForm"
                method="POST"
                onSubmit={(e) => {
                  handleSubmit(e)
                }}
                encType="multipart/form-data"
              >
                <div className="blgFormGroup">
                  <input
                    type="text"
                    className="blgInput"
                    placeholder="blog title"
                    name="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="blgFormGroup_flexxer">
                  <div className="input-field-row">
                    <div className="input-file-row">
                      <label htmlFor="project_brief" className="project_brief">
                        <div className="file-content">
                          <div className="icon">
                            <Upload className="image-upload-icon" />
                          </div>
                          <div className="content-label">
                            {uploading ? (
                              <span>uploading...</span>
                            ) : (
                              <span>Drag or browse your image here</span>
                            )}
                          </div>
                        </div>
                        <div className="row-wrap">
                          <div id="filePush" className="button">
                            upload
                          </div>
                        </div>
                      </label>
                      <input
                        className="file-field"
                        type="file"
                        name="images"
                        multiple
                        onChange={(e) =>
                          setImages(Array.from(e.target.files || []))
                        }
                      />
                    </div>
                    <span id="id-err fileErr"></span>
                  </div>
                  <div className="blgFormGroup" style={{ flex: '1' }}>
                    <ul className="blg_toc_list">
                      {Array.isArray(toclist) &&
                        toclist.map((item: any) => {
                          return (
                            <li className="blg_toc_list_item" key={item.id}>
                              <span>{item.title}</span>
                              <Xmark
                                className="list_item_remove_ic"
                                onClick={() => {
                                  setTocList(
                                    toclist.filter((obj) => obj.id !== item.id)
                                  )
                                }}
                              />
                            </li>
                          )
                        })}
                    </ul>
                    <div className="add_to_toc_box">
                      <input
                        type="text"
                        className="blgInput blgInput_toc"
                        placeholder="table of contents"
                        name="toc"
                        value={toc}
                        onChange={(e) => setToc(e.target.value)}
                      />
                      <button
                        className="btn_add_to_toc"
                        onClick={() => {
                          toc !== ''
                            ? setTocList([
                                ...toclist,
                                { title: toc, id: uuidv4() },
                              ])
                            : toast('cannot add empty toc content', {
                                type: 'error',
                              })
                        }}
                      >
                        add
                      </button>
                    </div>
                  </div>
                </div>
                <div className="blgFormGroup">
                  <input
                    type="text"
                    className="blgInput"
                    placeholder="blog summary"
                    name="summary"
                    value={summary}
                    onChange={(e) => setSummary(e.target.value)}
                  />
                </div>

                <div className="blgFormGroup">
                  <textarea
                    className="blgInput blgtxtareamd"
                    placeholder="write your blog in MarkDown (MD)"
                    name="blogcont"
                    value={blogcont}
                    onChange={(e) => setBlogCont(e.target.value)}
                  ></textarea>
                </div>
                <button id="filePush" className="button" type="submit">
                  {isLoading ? <div className="dot-flashing"></div> : 'post'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Blog
