import React, { Fragment, useEffect } from 'react'
import '../styles/css/playground.css'
import Dashboard from '../routes/Dashboard'
import { useAppDispatch, useAppSelector } from '../state/hooks'
import Blog from '../routes/Blog'
import Contacts from '../routes/Contacts'
import Partners from '../routes/Partners'
import Logs from '../routes/Logs'
import BlockedIPs from '../routes/BlockedIPs'
import Blogs from '../routes/Blogs'
import Comments from '../routes/Comments'
import Settings from '../routes/Settings'
import Profile from '../routes/Profile'
import { setTab } from '../state/actions/tabAction'
import EditBlog from '../routes/EditBlog'
import Files from '../routes/Files'

const Playground = () => {
  const dispatch = useAppDispatch()
  const tab = useAppSelector((state) => state.tab)
  useEffect(() => {
    const persistedStateJSON = localStorage.getItem('reduxState')
    if (persistedStateJSON) {
      const persistedState = JSON.parse(persistedStateJSON)
      if (persistedState) {
        dispatch(setTab(persistedState.tab))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Fragment>
      <div className="playground">
        {tab === 'dashboard' ? (
          <Dashboard />
        ) : tab === 'blog' ? (
          <Blog />
        ) : tab === 'blogs' ? (
          <Blogs />
        ) : tab === 'edit' ? (
          <EditBlog />
        ) : tab === 'contact' ? (
          <Contacts />
        ) : tab === 'comments' ? (
          <Comments />
        ) : tab === 'files' ? (
          <Files />
        ) : tab === 'partners' ? (
          <Partners />
        ) : tab === 'logs' ? (
          <Logs />
        ) : tab === 'ban' ? (
          <BlockedIPs />
        ) : tab === 'settings' ? (
          <Settings />
        ) : tab === 'profile' ? (
          <Profile />
        ) : null}
      </div>
    </Fragment>
  )
}

export default Playground
