import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAppSelector } from '../state/hooks'

const ProtectedRoutes = (): JSX.Element => {
  const islogged: any = useAppSelector((state) => state.islogged)
  let location = useLocation()
  return islogged ? (
    <Outlet />
  ) : (
    <Navigate to="/auth" state={{ from: location }} replace />
  )
}

export default ProtectedRoutes
