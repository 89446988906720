import React, { Fragment } from 'react'
import '../styles/css/header.css'
import { ReactComponent as User } from '../assets/svg/user.svg'
import { ReactComponent as Notifications } from '../assets/svg/bell.svg'
import { ReactComponent as Logout } from '../assets/svg/logout.svg'
import session from '../utils/session'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../state/hooks'
import { setTab } from '../state/actions/tabAction'

const Header = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const logout = () => {
    session.destroy()
    navigate('/auth')
  }
  return (
    <Fragment>
      <div className="header">
        <div className="headerLeft">
          <h2>nullchemy</h2>
        </div>
        <div className="headerRight">
          <User
            className="hrlnksIc"
            onClick={() => {
              dispatch(setTab('profile'))
            }}
          />
          <Notifications className="hrlnksIc" />
          <Logout className="hrlnksIc" onClick={() => logout()} />
        </div>
      </div>
    </Fragment>
  )
}

export default Header
