import React, { Fragment, useEffect, useState } from 'react'
import api from '../api/axios'
import '../styles/css/blogs.css'
import { useNavigate } from 'react-router-dom'
import PlaceHolder from '../assets/images/nullchemy_placeholder.jpg'

const Blogs = () => {
  let navigate = useNavigate()
  const [blogs, setBlogs] = useState({
    state: 'loading',
    data: Array<[]>,
  })

  useEffect(() => {
    const fetchBlog = async () => {
      const res = await api('GET', 'blog/all', {})
      if (res.status === 200) {
        setBlogs({ ...res.data, state: 'success' })
      } else {
        setBlogs({ data: Array<[]>, state: 'error' })
      }
    }
    fetchBlog()
  }, [blogs.data.length])
  const fetchBlog = async () => {
    setBlogs({ data: Array<[]>, state: 'loading' })
    const res = await api('GET', 'blog/all', {})
    if (res.status === 200) {
      setBlogs({ ...res.data, state: 'success' })
    } else {
      setBlogs({ data: Array<[]>, state: 'error' })
    }
  }

  //handle blog click
  const handleBlogRed = (blogid: string, blogslug: string) => {
    window.sessionStorage.setItem('blogid', blogid)
    navigate('/read/' + blogslug)
    window.scrollTo(0, 0)
  }
  return (
    <Fragment>
      <h2>BLogs</h2>
      <section className="blogs-showcase">
        <div className="highflex">
          {blogs.state === 'loading' ? (
            <div className="BloghlgloadingAnim">
              <div className="blog-item loading">
                <div className="blog-preview-image"></div>
                <div className="blog-details">
                  <div className="blog-title"></div>
                  <div className="blog-preview-text"></div>
                </div>
                <div className="blog-date"></div>
              </div>
              <div className="blog-item loading">
                <div className="blog-preview-image"></div>
                <div className="blog-details">
                  <div className="blog-title"></div>
                  <div className="blog-preview-text"></div>
                </div>
                <div className="blog-date"></div>
              </div>
              <div className="blog-item loading">
                <div className="blog-preview-image"></div>
                <div className="blog-details">
                  <div className="blog-title"></div>
                  <div className="blog-preview-text"></div>
                </div>
                <div className="blog-date"></div>
              </div>
            </div>
          ) : blogs.state === 'success' && Array.isArray(blogs.data) ? (
            blogs.data.map((i: any) => {
              console.log(i)

              if (blogs.data.length !== 0) {
                return (
                  <div
                    className="highblogcard"
                    onClick={() => {
                      handleBlogRed(i._id, i.slug)
                    }}
                    key={i._id}
                  >
                    <div className="bloghighImage">
                      <img
                        src={i.assets.length !== 0 ? i.assets[0] : PlaceHolder}
                        alt=""
                        id="blgImage"
                      />
                    </div>
                    <div className="bloghighTitle">
                      <h2>{i.title}</h2>
                      <p>{i.summary}</p>
                    </div>
                    <div className="bloghighInfo">
                      <p>
                        {new Date(i.createdAt).toLocaleString('en-US', {
                          month: 'long',
                          day: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        })}{' '}
                        . {i.length} min read
                      </p>
                    </div>
                  </div>
                )
              }
              return ''
            })
          ) : (
            <div className="errFetchBlogs">
              <p>
                Could'nt get blogs!{' '}
                <span
                  onClick={() => {
                    fetchBlog()
                  }}
                >
                  retry
                </span>
              </p>
            </div>
          )}
        </div>
      </section>
    </Fragment>
  )
}

export default Blogs
